import React, { useEffect, useState } from "react";
import styles from "./../celebrationEvent.module.css";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { imageListClasses } from "@mui/material";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import { Base_URL, Offers_URL } from "../../../../serverUrl";
import { addCelebrationBannerImage, deleteImage, findCelebrations, updateCelebrationData } from "../../../../api/adminPanel/giftCardApi";
import { UploadIcon, crossRed } from "../../../../utils/svg.file";
import swal from "sweetalert";
export default function EditBannerAndContent() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [file, setFile] = useState("");
  const [location, setLocation] = useState("");
  const [titledata, setTitledata] = useState("");
  const [description, setDescription] = useState("");
  const [imageList, setImageList] = useState([]);
  const [imgHome, setImgBanner] = useState("");
  const [fileList, setFileList] = useState([]);
  const [imageArray, setImageArray] = useState([]);
  const [metaDescription, setMetaDescription] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaTag, setMetaTag] = useState("");
  const locationList = [
    { id: 1, location: "Malaysia" },
    { id: 2, location: "Singapore" },
  ];
  
  const getSrcFromFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  };
  const onChange = async ({ fileList }) => {
    setFileList(fileList);
    setImgBanner(fileList[0].originFileObj);
    const addCelebrationImagePayload = {
      image: fileList[0].originFileObj,
    };
    const response = await addCelebrationBannerImage(
      addCelebrationImagePayload
    );

    if (response.remote === "success") {
      swal("Image Successfully Added");

      setImageArray([...imageArray, response.data.data]);
      setFileList([]);
    } else {
    }
  };
  const handelDeleteImgae = async (image) => {
    const newImageArray = imageArray.filter(
      (imageArray) => imageArray !== image
    );
    setImageArray(newImageArray);
    const deleteimages = {
      image: image,
    };

    const response = await deleteImage(deleteimages);

    if (response.remote === "success") {
      swal("Image Delete succesfully!");
    } else {
      swal("Something Went Wrong!");
    }
  };
  const onPreview = async (file) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };
  const handleCelebrationData = async () => {
    const response = await findCelebrations(id);

    if (response.remote === "success") {
      setLocation(response.data.data[0].location);
      setTitledata(response.data.data[0].title);
      setImageArray(JSON.parse(response.data.data[0].image));
      setDescription(response.data.data[0].description);
      setMetaTitle(response.data.data[0].meta_title);
      setMetaDescription(response.data.data[0].meta_description);
      setMetaTag(response.data.data[0].meta_tag);
    } else {
    }
  };

  const handleCelebrationUpdateData = async () => {
    const addCelebrationPayload = {
      id: id,
      image: imageArray,
      title: titledata,
      location: location,
      description: description,
      metaTitle: metaTitle,
      metaDescription: metaDescription,
      metaTag: metaTag,
    };
    const response = await updateCelebrationData(addCelebrationPayload);

    if (response.remote === "success") {
      swal("Data Successfully Updated");

      navigate("/manage-celebration-banner-content");
    } else {
    }
  };
  useEffect(() => {
    handleCelebrationData();
  }, []);

  return (
    <>
      <div className="">
        <div className="">
          <div className={styles.card_body}>
            <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
              <div className={styles.page_title}>
                <h3>Edit Celebration & Event Banner</h3>
              </div>
              <Button
                onClick={() => navigate(-1)}
                className={styles.main_button}
              >
                Back
              </Button>
            </div>
            <Form>
              <Row gutter={[24, 0]}>
                <Col md={6}>
                  <label className="fw-bold">Country</label>{" "}
                  <Form.Group className="">
                    <Form.Select
                      className={styles.form_input}
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                    >
                      {locationList.map((item, index) => {
                        return (
                          <>
                            <option key={index} value={item.id}>
                              {item.location}
                            </option>
                          </>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                  <label className="fw-bold">Title</label>
                  <Form.Control
                    type="text"
                    placeholder="Title"
                    className={styles.form_input}
                    value={titledata}
                    onChange={(e) => setTitledata(e.target.value)}
                  />
                </Col>
                <Col md={6}>
                  <label className="fw-bold mb-2">
                    {" "}
                    Banner Image{" "}
                    <small className="text-success small-msg">
                      (Max. Size 1219 X 757 px)
                    </small>
                  </label>
                  <div className="d-flex multi-images">
                    <ImgCrop grid aspect={1219 / 757}>
                      <Upload
                        action={Base_URL}
                        listType="picture-card"
                        fileList={fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                      >
                        {fileList.length < 1 && (
                          <>
                            <div className="file_upload_div">
                              <span>{UploadIcon}</span>
                              <p>Click Here to Upload</p>
                            </div>
                          </>
                        )}
                      </Upload>
                    </ImgCrop>
                    <ul className={styles.multi_image_preview}>
                      {imageArray?.map((item, index) => {
                        return (
                          <>
                            <li className={styles.upload_list_image}>
                              <img src={`${Offers_URL}${item}`} alt="" />
                              <div className={styles.action_btn}>
                                <span
                                  className={styles.delete_icon_btn}
                                  onClick={() => handelDeleteImgae(item)}
                                >
                                  {crossRed}
                                </span>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <label className="fw-bold">Short Description</label>

                  <textarea
                    rows="3"
                    type="text"
                    placeholder="Description"
                    className={styles.form_input}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Col>
                <label className="fw-bold">Enter Meta</label>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Title"
                      className={styles.form_input}
                      value={metaTitle}
                      onChange={(e) => setMetaTitle(e.target.value)}
                    />
                  </Col>
                  <Col md={12}>
                    {" "}
                    <Form.Control
                      type="text"
                      placeholder="Meta Description"
                      className={styles.form_input}
                      value={metaDescription}
                      onChange={(e) => setMetaDescription(e.target.value)}
                    />
                  </Col>
                <Col md={12} className="text-center  mt-5">
                  <Button
                    className={styles.main_button}
                    onClick={handleCelebrationUpdateData}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
